import { Button, Container, Grid, Input, Space, Table, Title, Tooltip } from "@mantine/core";
import { users } from "../constants/users.constant";
import { User } from "../interfaces/user.interface";
import { IconEdit, IconPlus, IconTrash, IconUser, IconUserOff } from "@tabler/icons-react";

const UsersContainer = () => {
    return (
        <Container fluid>
            <Grid>
                <Grid.Col span={{ base: 12 }}>
                    <Title order={2}>Liste des utilisateurs ({users.length})</Title>
                </Grid.Col>
            </Grid>
            <Space h="lg" />
            <Grid>
                <Grid.Col span={{ base: 4 }}>
                    <Input placeholder="Rechercher par ici..." />
                </Grid.Col>               
                <Grid.Col span={{ base: 8 }}>
                    <Button variant="filled" leftSection={<IconPlus size={14} />}>Nouvel utilisateur</Button>
                </Grid.Col>
            </Grid>
            <Space h="lg" />
            <Grid>
                <Grid.Col span={{ base: 12 }}>
                    <Table stickyHeader stickyHeaderOffset={60}>
                        <Table.Thead>
                            <Table.Tr>
                            <Table.Th>#</Table.Th>
                            <Table.Th>Nom</Table.Th>
                            <Table.Th>Email</Table.Th>
                            <Table.Th>Rôle</Table.Th>
                            <Table.Th>Statut</Table.Th>
                            <Table.Th>Actions</Table.Th>                            
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {users.map((user: User, i: number) => (
                                <Table.Tr key={`user-${i}`}>
                                <Table.Td>{user.id}</Table.Td>
                                <Table.Td>{user.prenom} {user.nom}</Table.Td>
                                <Table.Td>{user.email}</Table.Td>
                                <Table.Td>{user.role}</Table.Td>
                                <Table.Td>{user.actif ? "Actif" : "Inactif"}</Table.Td>
                                <Table.Td>
                                    <Button.Group>
                                        <Tooltip label="Editer">
                                            <Button variant="default"><IconEdit color="#00abfb" /></Button>
                                        </Tooltip>
                                        <Tooltip label="Supprimer">
                                            <Button variant="default"><IconTrash color="#ff2825" /></Button>
                                        </Tooltip>
                                        <Tooltip label={user.actif ? "Désactiver" : "Activer" }>
                                            <Button variant="default">
                                                {user.actif ? <IconUserOff color="#ffbf00" /> : <IconUser color="#00b341" />}
                                            </Button>
                                        </Tooltip>
                                    </Button.Group>
                                </Table.Td>
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                        <Table.Caption>Scroll page to see sticky thead</Table.Caption>
                    </Table>
                </Grid.Col>
            </Grid>
        </Container>
    )
}

export default UsersContainer;