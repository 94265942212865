import React from 'react';
import { Container, Grid, Group, Space } from '@mantine/core';
import ContactCard from '../components/ContactCard';
import { IconPhone, IconMail, IconAddressBook, IconBrandFacebook, IconBrandInstagram, IconBrandYoutube, IconAdjustments, IconPencil } from '@tabler/icons-react';
import TitlePage from '../../../shared/components/TitlePage';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ModalForm from '../components/ModalForm';

const SettingContainer: React.FC = () => {
    const contacts = [
        { label: "Email", value: 'contact@citedescultures.com', icon: <IconMail color='purple' size="2rem" stroke={1.5} /> },
        { label: "Telephone", value: '+261 32 03 795 41', icon: <IconPhone color='purple' size="2rem" stroke={1.5} /> },
        { label: "Adresse", value: "2 place de l'indépendance antaninarenina, Antananarivo 101", icon: <IconAddressBook color='purple' size="2rem" stroke={1.5} /> },
        { label: "Facebook", value: 'https://www.facebook.com/profile.php?id=61559194183233', icon: <IconBrandFacebook color='purple' size="2rem" stroke={1.5} /> },
        { label: "Instagram", value: 'https://www.instagram.com/citedescultures/', icon: <IconBrandInstagram color='purple' size="2rem" stroke={1.5} /> },
        { label: "Youtube", value: 'https://www.youtube.com/@CitedesCultures', icon: <IconBrandYoutube color='purple' size="2rem" stroke={1.5} /> },
    ];

    const [openModal, { open, close }] = useDisclosure(false);

    return (
        <Container fluid>
            <TitlePage title='Paramètres' icon={<IconAdjustments size="2rem" stroke={1.5} />} />
            <Group justify="flex-end">
                <Button onClick={open} leftSection={<IconPencil size={"1.2rem"} />} variant="filled" color="grape">Modifier </Button>
            </Group>
            <Space h="lg" />
            <Grid >
                {contacts.map(({ label, value, icon }, index) => (
                    <Grid.Col key={index} span={{ base: 12, xs: 12, sm: 12, md: 6 }}>
                        <ContactCard label={label} value={value} icon={icon} />
                    </Grid.Col>
                ))}
            </Grid>

            <ModalForm opened={openModal} onClose={close} />
        </Container>

    );
};

export default SettingContainer;