import { Button, Container, FileInput, Grid, Group, TextInput } from '@mantine/core'
import React from 'react'
import TitlePage from '../../../shared/components/TitlePage'
import { IconDeviceFloppy, IconDoor, IconX } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

const RoomEditContainer = () => {
    const navigate = useNavigate();

    return (
        <Container fluid>
            <TitlePage title="Salle" icon={<IconDoor size="2rem" stroke={1.5} />} />
            <Grid>
                <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                    <TextInput
                        label="Nom de la salle"
                        placeholder="Entrez le nom"
                        name="name"
                        // value={event.name}
                        // onChange={handleInputChange}
                        required
                        my={15}
                    />
                    <TextInput
                        label="Personne minimum"
                        placeholder="Entrez le nombre de personne "
                        name="personMin"
                        // value={event.hourStart}
                        // onChange={handleInputChange}
                        required
                        my={15}
                    />


                    <TextInput
                        label="Surface de la salle"
                        placeholder="Entrez le type "
                        name="type"
                        // value={event.hourStart}
                        // onChange={handleInputChange}
                        required
                        my={15}
                    />

                </Grid.Col>

                <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                    <TextInput
                        label="Type de la salle"
                        placeholder="Entrez le type "
                        name="type"
                        // value={event.hourStart}
                        // onChange={handleInputChange}
                        required
                        my={15}

                    />

                    <TextInput
                        label="Personne maximum"
                        placeholder="Entrez le nombre de personne "
                        name="personMax"
                        // value={event.hourStart}
                        // onChange={handleInputChange}
                        // required
                        my={15}
                    />
                    <TextInput
                        label="Supplement"
                        placeholder="Entrez les supplements "
                        name="supplement"
                        // value={event.hourStart}
                        // onChange={handleInputChange}
                        // required
                        my={15}
                    />
                </Grid.Col>

                <Grid.Col span={{ base: 12 }}>
                    <FileInput
                        label="Image de le salle"
                        placeholder="Téléchargez une image"
                        // icon={<IconUpload />}
                        // onChange={handleImageChange}
                        my={15}

                    />
                </Grid.Col>

                <Grid.Col span={{ base: 12 }}>
                    <Group justify="flex-end" gap="md">
                        <Button mt="md" variant="filled" color="teal" leftSection={<IconDeviceFloppy size={14} />} onClick={() => (navigate("/room"))}>
                            Enregistrer
                        </Button>
                        <Button mt="md" variant="filled" color="pink" leftSection={<IconX size={14} />} onClick={() => (navigate("/room"))}>
                            Annuler
                        </Button>
                    </Group>

                </Grid.Col>
            </Grid>
        </Container>
    )
}

export default RoomEditContainer
