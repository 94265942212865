export interface Inotification{
    id:number,
    name:string,
    email:string,
    phone:string,
    message:string,
    status:enumNotification
}

enum enumNotification{
    noRead=0,
    read=1,
}

export const notificationData : Inotification[]=[
    {
        id:1,
        name:"Enzo anjaratiana",
        email:"enzo@gmail.com",
        phone:"023562632",
        message:"Fait comme moi dans la maison",
        status:0
    },
    {
        id:2,
        name:"Call mome",
        email:"mome@gmail.com",
        phone:"023562632",
        message:"Je sais faire ca",
        status:0
    },
    {
        id:3,
        name:"Enzo anjaratiana",
        email:"enzo@gmail.com",
        phone:"023562632",
        message:"Donne mois ce qui est juste",
        status:0

    },
]