import { Container, Grid, Space, Table } from '@mantine/core'
import TitlePage from '../../../shared/components/TitlePage'
import { IconMessage } from '@tabler/icons-react'
import { Inotification, notificationData } from '../constants/notification.constant'

const NotificationContainer = () => {
    return (
        <Container fluid>
            <TitlePage title="Notification" icon={<IconMessage size="2rem" stroke={1.5} />} />
            <Space h="lg" />
            <Grid>

                <Grid.Col span={{ base: 12 }}>
                    <Table stickyHeader striped highlightOnHover horizontalSpacing="xl" withRowBorders={false}>
                        <Table.Thead style={{ backgroundColor: 'purple', color: "white" }}>
                            <Table.Tr>
                                <Table.Th>#</Table.Th>
                                <Table.Th>Nom et prenom</Table.Th>
                                <Table.Th>Email</Table.Th>
                                <Table.Th>Telephone</Table.Th>
                                <Table.Th>Message</Table.Th>
                                <Table.Th>Status</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {
                                notificationData.map((item:Inotification,i:number)=>(
                                    <Table.Tr>
                                        <Table.Td> {i+1}</Table.Td>
                                        <Table.Td> {item.name}</Table.Td>
                                        <Table.Td> {item.email}</Table.Td>
                                        <Table.Td> {item.phone}</Table.Td>
                                        <Table.Td> {item.message}</Table.Td>
                                        <Table.Td> {item.status}</Table.Td>
                                    </Table.Tr>
                                ))
                            }
                        </Table.Tbody>
                    </Table>
                </Grid.Col>
            </Grid>

        </Container>
    )
}

export default NotificationContainer
