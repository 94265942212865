import React, { useState } from 'react';
import { Container, Grid, TextInput, Select, Textarea, FileInput, Button, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { IEvent } from '../constants/events.interface';
import apiService from '../../../shared/services/apiService';
import { convertToISO } from '../../../shared/services/utilsService';

const EventForm: React.FC = () => {
    const navigate = useNavigate();
    const [event, setEvent] = useState<IEvent>({
        name: '',
        eventType: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        room: '',
        description: '',
        image: undefined,
    });

    const [errors, setErrors] = useState<{ [key in keyof IEvent]?: string }>({});

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Le nom de l\'événement est requis'),
        eventType: Yup.string().required('Le type d\'événement est requis'),
        startDate: Yup.string()
            .required('La date de début est requise')
            .matches(/^\d{4}-\d{2}-\d{2}$/, 'Doit être au format YYYY-MM-DD'),
        room: Yup.string().required('La salle est requise'),
        description: Yup.string().required('Le type d\'événement est requis'),
        image: Yup.string().nullable(),
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setEvent({ ...event, [name]: value });
    };

    const handleSelectChange = (value: string | null) => {
        if (value) {
            setEvent({ ...event, eventType: value });
        } else {
            setEvent({ ...event, eventType: '' });
        }
    };

    const handleImageChange = (file: File | null) => {
        if (file) {
            setEvent({ ...event, image: file });
        } else {
            setEvent({ ...event, image: undefined });
        }
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await validationSchema.validate(event, { abortEarly: false });

            const formData = new FormData();
            formData.append('name', event.name);
            formData.append('eventType', event.eventType);
            formData.append('startTime', event.startTime);
            formData.append('room', event.room);

            if (event.startDate) {
                formData.append('startDate',convertToISO(event.startDate) );
            } else {
                formData.append('startDate', ""); 
            }

            if (event.endDate) {
                formData.append('endDate', convertToISO(event.endDate) );
            }

            if (event.description) {
                formData.append('description', event.description);
            }

            if (event.image) {
                formData.append('image', event.image);
            }
            Array.from(formData.entries()).forEach(([key, value]) => {
                console.log(`${key}: ${value}`);
                console.log(` ===>${typeof (value)}`);
            });

            await apiService.createEvent(formData);
          
            navigate("/events");
        } catch (validationErrors: any) {
            const formattedErrors: { [key in keyof IEvent]?: string } = {};

            if (validationErrors.inner && Array.isArray(validationErrors.inner)) {
                validationErrors.inner.forEach((error: { path: keyof IEvent; message: string }) => {
                    formattedErrors[error.path] = error.message;
                });
            }
            setErrors(formattedErrors);
        }
    };




    return (
        <Container fluid>
            <h2>Créer un Événement</h2>
            <form onSubmit={handleSubmit}>
                <Grid>
                    <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                        <TextInput
                            label="Nom de l'événement"
                            placeholder="Entrez le nom"
                            name="name"
                            value={event.name}
                            onChange={handleInputChange}
                            required
                            my={15}
                            error={errors.name}
                        />
                        <TextInput
                            label="Date de début"
                            placeholder="YYYY-MM-DD"
                            name="startDate"
                            type="date"
                            value={event.startDate}
                            onChange={handleInputChange}
                            required
                            my={15}
                            error={errors.startDate}
                        />
                        <TextInput
                            label="Heure de début"
                            placeholder="HH:MM"
                            name="startTime"
                            type="time"
                            value={event.startTime}
                            onChange={handleInputChange}
                            required
                            my={15}
                            error={errors.startTime}
                        />
                        <TextInput
                            label="Salle"
                            placeholder="Entrez la salle"
                            name="room"
                            value={event.room}
                            onChange={handleInputChange}
                            required
                            my={15}
                            error={errors.room}
                        />
                        <FileInput
                            label="Image de l'événement"
                            placeholder="Téléchargez une image"
                            onChange={handleImageChange}
                            accept="image/png,image/jpeg,image/gif"
                            my={15}
                        />
                    </Grid.Col>

                    <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                        <Select
                            label="Type d'événement"
                            placeholder="Sélectionner le type"
                            data={['Atelier', 'Conférence', 'Séminaire']}
                            value={event.eventType}
                            onChange={handleSelectChange}
                            required
                            my={15}
                            error={errors.eventType}
                        />
                        <TextInput
                            label="Date de fin"
                            placeholder="YYYY-MM-DD"
                            name="endDate"
                            type="date"
                            value={event.endDate ? event.endDate : ''}
                            onChange={handleInputChange}
                            my={15}
                            error={errors.endDate}
                        />
                        <TextInput
                            label="Heure de fin"
                            placeholder="HH:MM"
                            name="endTime"
                            type="time"
                            value={event.endTime}
                            onChange={handleInputChange}
                            my={15}
                            error={errors.endTime}
                        />
                        <Textarea
                            label="Description"
                            placeholder="Entrez une description"
                            name="description"
                            value={event.description}
                            onChange={handleInputChange}
                            my={15}
                            error={errors.description}
                        />
                    </Grid.Col>

                    <Grid.Col span={{ base: 12 }}>
                        <Group justify="flex-end" gap="md">
                            <Button mt="md" variant="filled" color="teal" type="submit">
                                Enregistrer
                            </Button>
                            <Button mt="md" variant="filled" color="pink" onClick={() => navigate("/events")}>
                                Annuler
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form> {/* Fin du formulaire */}
        </Container>
    );
};

export default EventForm;
