import { Container, Grid } from "@mantine/core";
import DashboardPaper from "../components/DashboardPaper/DashboardPaper";
import DashboardTitle from "../components/DashboardTitle/DashboardTitle";
import { areaChartData } from "../constants/data";
import { AreaChart, Sparkline  } from '@mantine/charts';

const DashboardContainer: React.FC<any> = () => {
    return (
        <Container fluid>
            <Grid>
                <Grid.Col span={{ base: 12 }}>
                    <DashboardTitle text="Bilan Avril - 2024" color="#9C2781" />
                </Grid.Col>
            </Grid>            
            <Grid>
                <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 3 }}>
                    <DashboardPaper totalNumber={40} description="Evènements" />
                </Grid.Col>
                <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 3 }}>
                    <DashboardPaper totalNumber={6} description="Location de salle" />
                </Grid.Col>
                <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 3 }}>
                    <DashboardPaper totalNumber={12} description="Nouveaux membres" />
                </Grid.Col>
                <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 3 }}>
                    <DashboardPaper totalNumber={28} description="Factures impayées" />
                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col span={{ base: 12 }} style={{marginTop: 30}}>
                    <DashboardTitle text="Charte graphique" color="#9C2781" />
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 6}}>
                    <AreaChart
                        h={300}
                        data={areaChartData}
                        dataKey="date"
                        series={[
                            { name: 'Apples', color: 'indigo.6' },
                            { name: 'Oranges', color: 'blue.6' },
                            { name: 'Tomatoes', color: 'teal.6' },
                        ]}
                        curveType="linear"
                    />
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 6}}>
                    <Sparkline
                        h={300}
                        data={[10, 20, 40, 20, 40, 10, 50]}
                        curveType="linear"
                        color="grape"
                        fillOpacity={0.6}
                        strokeWidth={2}
                    />
                </Grid.Col>
            </Grid>
        </Container>
    );
}

export default DashboardContainer;