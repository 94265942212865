import  { useState } from 'react'
import TitlePage from '../../../shared/components/TitlePage'
import { Table, Image, Group, Modal, Button, Container, Grid, Space, Center, Tooltip, Switch, useMantineTheme, rem } from '@mantine/core';
import {  IconTrash, IconCheck, IconX, IconBell, IconPhoto } from '@tabler/icons-react';

import { data, Ialaune } from '../constants/alaune.constant'
import ModalAlaune from '../components/modalAlaune';

const AlauneContainer = () => {
  const [openedApercu, setOpenedApercu] = useState(false);
  const [openedAddImageModal, setopenedAddImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dataAlaune, setData] = useState<Ialaune[]>(data);
  const theme = useMantineTheme();


  const handleImageClick = (imgSrc: any) => {
    setSelectedImage(imgSrc);
    setOpenedApercu(true);
  };

  const handleSwitchChange = (id: number, checked: boolean) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, active: checked } : item
      )
    );
  };

  return (
    <Container fluid>
      <TitlePage title='A la une ' icon={<IconBell size="2rem" stroke={1.5} />} />
      <Space h="lg" />
      <Group justify="flex-end">
        <Button leftSection={<IconPhoto size={14} />} variant="filled" color="grape" onClick={() => setopenedAddImageModal(true)}>
          Ajouter
        </Button>
      </Group>
      <Space h="lg" />
      <Grid>
        <Grid.Col span={{ base: 12, sm: 12, md: 4 }}>
          <Center>
            <Image
              radius="md"
              h={400}
              w="auto"
              fit="contain"
              src={"/images/alaune/alaune.png"}
              fallbackSrc="/images/default.png"
              alt='A laune'
            />
          </Center>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 12, md: 8 }}>
          <Table stickyHeader striped highlightOnHover horizontalSpacing="xl" withRowBorders={false}>
            <Table.Thead style={{ backgroundColor: 'purple', color: "white" }}>
              <Table.Tr>
                <Table.Th>#</Table.Th>
                <Table.Th>Nom</Table.Th>
                <Table.Th>Image</Table.Th>
                <Table.Th>Actions</Table.Th>
                <Table.Th>Active</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {
                dataAlaune.map((item: Ialaune, i: number) => (
                  <Table.Tr key={item.id}>
                    <Table.Td> {i + 1} </Table.Td>
                    <Table.Td> {item.name} </Table.Td>
                    <Table.Td>
                      <Image
                        src={item.imgSrc}
                        alt={item.name}
                        height={60}
                        radius="md"
                        onClick={() => handleImageClick(item.imgSrc)}
                        style={{ cursor: 'pointer' }}
                      />
                    </Table.Td>
                    <Table.Td>
                      <Button.Group>
                        <Tooltip label="Supprimer">
                          <Button variant='default'> <IconTrash size="1.25rem" /> </Button>
                        </Tooltip>
                      </Button.Group>
                    </Table.Td>
                    <Table.Td>
                      <Switch
                        checked={item.active}
                        onChange={(event) => handleSwitchChange(item.id, event.currentTarget.checked)}
                        color="teal"
                        size="md"
                        thumbIcon={
                          item.active ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color={theme.colors.teal[6]}
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color={theme.colors.red[6]}
                              stroke={3}
                            />
                          )
                        }
                      />
                    </Table.Td>
                  </Table.Tr>
                ))
              }
            </Table.Tbody>
          </Table>
        </Grid.Col>
      </Grid>

      <Modal opened={openedApercu} onClose={() => setOpenedApercu(false)} title="Aperçu de l'image" size="auto">
        <Image
          radius="md"
          h={400}
          w="auto"
          fit="contain"
          src={selectedImage}
          fallbackSrc="/images/default.png"
          alt='A laune'
        />
        <Button fullWidth mt="md" onClick={() => setOpenedApercu(false)}>
          Fermer
        </Button>
      </Modal>
      <ModalAlaune opened={openedAddImageModal} onClose={() => setopenedAddImageModal(false)} />
    </Container>
  )
}

export default AlauneContainer
