import React, { useState, FormEvent, ChangeEvent } from 'react';
import { Modal, Button, TextInput, FileInput, Group, Space } from '@mantine/core';
import { FormModalInterface } from '../../../shared/Interfaces/modal.interface';
import {FormValues} from "../constants/alaune.constant"

const ModalAlaune: React.FC<FormModalInterface> = ({ opened, onClose }) => {
  const [formValues, setFormValues] = useState<FormValues>({ name: '', file: null });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormValues({ name: '', file: null });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValues(prev => ({
      ...prev,
      name: event.target.value
    }));
  };

  // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files ? event.target.files[0] : null;
  //   setFormValues(prev => ({
  //     ...prev,
  //     file
  //   }));
  // };

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title="Téléchargez une image et saisissez un nom"
      >
        <form onSubmit={handleSubmit}>
         <Space h="lg"/>
          <TextInput
            label="Nom"
            value={formValues.name}
            onChange={handleNameChange}
            required
          />
          <Space h="lg"/>
          <FileInput
            label="Télécharger une image"
            accept="image/*"
            value={formValues.file}
            required
          />
          <Space h="lg"/>

          <Group  mt="md">
            <Button type="submit">Valider</Button>
            <Button variant="outline" onClick={onClose}>Annuler</Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default ModalAlaune;
