import { Role, User } from "../interfaces/user.interface";

export const users: User[] = [
    {
        id: 1,
        nom: "Dupont",
        prenom: "Jean",
        email: "jean.dupont@example.com",
        motDePasse: "motdepasse1",
        role: Role.ADMINISTRATEUR,
        actif: true,
    },
    {
        id: 2,
        nom: "Martin",
        prenom: "Sophie",
        email: "sophie.martin@example.com",
        motDePasse: "motdepasse2",
        role: Role.UTILISATEUR,
        actif: false,
    },
    {
        id: 3,
        nom: "Lefevre",
        prenom: "Pierre",
        email: "pierre.lefevre@example.com",
        motDePasse: "motdepasse3",
        role: Role.ADMINISTRATEUR,
        actif: true,
    },
    {
        id: 4,
        nom: "Dubois",
        prenom: "Marie",
        email: "marie.dubois@example.com",
        motDePasse: "motdepasse4",
        role: Role.UTILISATEUR,
        actif: true,
    },
    {
        id: 5,
        nom: "Roux",
        prenom: "Luc",
        email: "luc.roux@example.com",
        motDePasse: "motdepasse5",
        role: Role.ADMINISTRATEUR,
        actif: false,
    },
    {
        id: 6,
        nom: "Moreau",
        prenom: "Emma",
        email: "emma.moreau@example.com",
        motDePasse: "motdepasse6",
        role: Role.UTILISATEUR,
        actif: true,
    },
    {
        id: 7,
        nom: "Garcia",
        prenom: "Thomas",
        email: "thomas.garcia@example.com",
        motDePasse: "motdepasse7",
        role: Role.ADMINISTRATEUR,
        actif: false,
    },
    {
        id: 8,
        nom: "Fournier",
        prenom: "Camille",
        email: "camille.fournier@example.com",
        motDePasse: "motdepasse8",
        role: Role.UTILISATEUR,
        actif: true,
    },
    {
        id: 9,
        nom: "Legrand",
        prenom: "Mathilde",
        email: "mathilde.legrand@example.com",
        motDePasse: "motdepasse9",
        role: Role.ADMINISTRATEUR,
        actif: true,
    },
    {
        id: 10,
        nom: "Leroy",
        prenom: "Antoine",
        email: "antoine.leroy@example.com",
        motDePasse: "motdepasse10",
        role: Role.UTILISATEUR,
        actif: false,
    },
];
