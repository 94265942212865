import Cookies from 'js-cookie';

class TokenService {
  private static TOKEN_KEY = 'token';

  static setToken(token: string): void {
    Cookies.set(this.TOKEN_KEY, token, {
      expires: 7, 
      secure: true, 
      sameSite: 'Strict', 
    });
  }

  static getToken(): string | undefined {
    return Cookies.get(this.TOKEN_KEY);
  }

  static removeToken(): void {
    Cookies.remove(this.TOKEN_KEY);
  }
}

export default TokenService;
