import React from 'react';
import { Modal, Button, TextInput, Stack } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {schema, FormValues} from '../interfaces/setting.interface'
import '../styles/style.module.css'
import { FormModalInterface } from '../../../shared/Interfaces/modal.interface';

const ModalForm: React.FC<FormModalInterface> = ({ opened, onClose }) => {
  const { control, handleSubmit, formState: { errors } } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (data: FormValues) => {
    console.log(data);
    onClose(); 
  };

  return (
    <Modal opened={opened} onClose={onClose} title="MODIFICATION PARAMETRE"  centered size="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack style={{marginTop:'25px'}}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                label="Email"
                placeholder="Entrez votre e-mail"
                {...field}
                error={errors.email?.message}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextInput
                label="Téléphone"
                placeholder="Entrez votre numéro de téléphone"
                {...field}
                error={errors.phone?.message}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextInput
                label="Adresse"
                placeholder="Entrez votre adresse"
                {...field}
                error={errors.address?.message}
              />
            )}
          />
          <Controller
            name="facebook"
            control={control}
            render={({ field }) => (
              <TextInput
                label="Lien Facebook"
                placeholder="Entrez le lien de votre profil Facebook"
                {...field}
                error={errors.facebook?.message}
              />
            )}
          />
          <Controller
            name="instagram"
            control={control}
            render={({ field }) => (
              <TextInput
                label="Lien Instagram"
                placeholder="Entrez le lien de votre profil Instagram"
                {...field}
                error={errors.instagram?.message}
              />
            )}
          />
          <Controller
            name="youtube"
            control={control}
            render={({ field }) => (
              <TextInput
                label="Lien YouTube"
                placeholder="Entrez le lien de votre chaîne YouTube"
                {...field}
                error={errors.youtube?.message}
              />
            )}
          />
          <Button type="submit" variant="filled" color="grape">
            Valider
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default ModalForm;
