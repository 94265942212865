import { Badge, NavLink } from '@mantine/core';
import {
    IconHome2,
    IconCalendarEvent,
    IconBuilding,
    IconAffiliate,
    IconAdjustments,
    IconUsers,
    IconBell,
    IconDoor,
    IconMail,
    IconLogout
} from '@tabler/icons-react';
import { ReactNode } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import TokenService from '../services/TokenService';

interface INavLink {
    label: string;
    href: string;
    active?: boolean;
    icon?: ReactNode;
    leftSection?: ReactNode;
    rightSection?: ReactNode;
    items?: INavLink[];
}

const MENU: INavLink[] = [{
    label: "Accueil",
    href: "/dashboard",
    leftSection: <IconHome2 size="1rem" stroke={1.5} />
}, {
    label: "Evénements",
    href: "/events",
    leftSection: <IconCalendarEvent size="1rem" stroke={1.5} />
}, {
    label: "Location salle",
    href: "/rental",
    leftSection: <IconBuilding size="1rem" stroke={1.5} />
}, {
    label: "Salle",
    href: "/room",
    leftSection: <IconDoor size="1rem" stroke={1.5} />
}, {
    label: "A la une",
    href: "/alaune",
    leftSection: <IconBell size="1rem" stroke={1.5} />
}, {
    //     label: "Membres",
    //     href: "/members",
    //     leftSection: <IconUsersGroup size="1rem" stroke={1.5} />
    // }, {
    //     label: "Cours",
    //     href: "/courses",
    //     leftSection: <IconCertificate size="1rem" stroke={1.5} />
    // }, {
    //     label: "Finance",
    //     href: "/finance",
    //     leftSection: <IconMoneybag size="1rem" stroke={1.5} />
    // }, {
    label: "Notification",
    href: "/notification",
    leftSection: <IconMail size="1rem" stroke={1.5} />
}, {
    label: "Collaborateurs/Partenaires",
    href: "collaborateurs",
    leftSection: <IconAffiliate size="1rem" stroke={1.5} />
},
{
    label: "Paramétrage",
    href: "/parametrage",
    leftSection: <IconAdjustments size="1rem" stroke={1.5} />
},
{
    label: "Utilisateurs",
    href: "/utilisateurs",
    leftSection: <IconUsers size="1rem" stroke={1.5} />
}];

const handleLogout = () => {
 
    TokenService.removeToken();
};

function MyNavLink() {
    const location = useLocation();
    return (
        <>
            {MENU.map((menu: INavLink, i: number) => {
                return <NavLink
                    component={Link}
                    to={menu.href}
                    variant="filled"
                    key={`menu-${i}`}
                    active={menu.href === location.pathname}
                    leftSection={menu.leftSection}
                    label={
                        menu.href === '/notification' ? (
                            <>
                                {menu.label}
                                <Badge variant='danger' size="sm" circle ml={5}>1</Badge>
                            </>
                        ) : (
                            menu.label
                        )
                    }
                />
            })}
            {/* <NavLink
                component={Link}
                to="/login"
                variant="filled"
                leftSection={<IconLogout size="1rem" stroke={1.5} />}
                label="Déconnecter"
                style={{ marginTop: 'auto' }}
            /> */}
            <NavLink
                component={Link}
                to="/login"
                variant="filled"
                leftSection={<IconLogout size="1rem" stroke={1.5} />}
                label="Déconnecter"
                style={{ marginTop: 'auto' }}
                onClick={handleLogout}
            />
        </>
    );
}

export default MyNavLink;