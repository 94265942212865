import React from 'react';
import { Card, Text } from '@mantine/core';
import { ContactCardProps } from '../interfaces/setting.interface';


const ContactCard: React.FC<ContactCardProps> = ({label, value, icon }) => {
  return (
    <Card shadow="sm" padding="xl">
      {icon}
      <Text fw={500} size="lg" mt="md">
      {label}
      </Text>
      <Text mt="xs" c="dimmed" size="sm">
        {value}
      </Text>
    </Card>
  );
};

export default ContactCard;
