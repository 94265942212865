export interface Ialaune {
    id: number,
    name: string,
    imgSrc: string,
    active: boolean
}

export const data: Ialaune[] = [
    { id: 1, name: 'Article 1', imgSrc: '/images/alaune/alaune.png', active: true },
    { id: 2, name: 'Article 2', imgSrc: '/images/default.png', active: false },
];

export interface FormValues {
    name: string;
    file: File | null;
}
