import { Container, Button, Group, Grid, Space, Table, Tooltip } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import TitlePage from '../../../shared/components/TitlePage'
import { IconEdit, IconCalendarEvent, IconTrash, IconPlus } from "@tabler/icons-react";
// import { events, Ievenement } from '../constants/events.constants';
import { useNavigate } from 'react-router-dom';
import { IEvent } from '../constants/events.interface';
import apiService from '../../../shared/services/apiService';
import { useModals } from '@mantine/modals';

const EventsContainer = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState<IEvent[]>([]);
    const [loading, setLoading] = useState(true);
    const modals = useModals();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiService.getEvent();
                setEvents(response);
            } catch (error) {
                console.error("Error fetching events:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    const handleEdit = (id: number) => {
        navigate(`/events/edit/${id}`);
    };

    const handleDelete = async (id: number) => {
        modals.openConfirmModal({
            title: 'Confirmation de suppression',
            children: <p>Êtes-vous sûr de vouloir supprimer cet événement ? Cette action est irréversible.</p>,
            labels: { confirm: 'Supprimer', cancel: 'Annuler' },
            confirmProps: { color: 'red' },
            onConfirm: async () => {
              try {
                await apiService.deleteEvent(id);
                setEvents(events.filter(event => event.id !== id));
                console.log("delete")
              } catch (error) {
                console.error("Erreur lors de la suppression de l'événement:", error);
              }
            },
          });
        // const confirmed = window.confirm("Are you sure you want to delete this event?");
        // if (confirmed) {
        //     try {
        //         // await apiService.deleteEvent(id);
        //         // setEvents(events.filter(event => event.id !== id));
        //     } catch (error) {
        //         console.error("Error deleting event:", error);
        //     }
        // }

    };

    return (
        <Container fluid>
            <TitlePage title="Evenements" icon={<IconCalendarEvent size="2rem" stroke={1.5} />} />
            <Group justify="flex-end">
                <Button leftSection={<IconPlus size={14} />} variant="filled" color="grape" onClick={() => (navigate("/events/edit"))}>
                    Ajouter
                </Button>
            </Group>
            <Space h="lg" />
            <Grid>
                <Grid.Col span={{ base: 12 }}>
                    <Table stickyHeader stickyHeaderOffset={60} highlightOnHover horizontalSpacing="xl" withRowBorders={false}>
                        <Table.Thead style={{ backgroundColor: 'purple', color: "white" }}>
                            <Table.Tr>
                                <Table.Th >Nom</Table.Th>
                                <Table.Th>date</Table.Th>
                                <Table.Th>heure</Table.Th>
                                <Table.Th>Type</Table.Th>
                                <Table.Th>Lieu</Table.Th>
                                <Table.Th>Description</Table.Th>
                                <Table.Th>Actions</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {events.map((item: IEvent, i: number) => (
                                <Table.Tr key={`item-${i}`} >
                                    <Table.Td >{item.name}</Table.Td>
                                    <Table.Td >{item.startDate}</Table.Td>
                                    <Table.Td > {`${item.startTime} - ${item.endTime}`}</Table.Td>
                                    <Table.Td >{item.eventType}</Table.Td>
                                    <Table.Td >{item.room}</Table.Td>
                                    <Table.Td >{item.description}</Table.Td>
                                    <Table.Td >
                                        <Button.Group>
                                            <Tooltip label="Editer">
                                                <Button variant="default" onClick={() => item.id !== undefined && handleEdit(item.id)}><IconEdit color="#00abfb" /></Button>
                                            </Tooltip>
                                            <Tooltip label="Supprimer">
                                                <Button variant="default" onClick={() => item.id !== undefined && handleDelete(item.id)}><IconTrash color="#ff2825" /></Button>
                                            </Tooltip>
                                        </Button.Group>
                                    </Table.Td>
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                    </Table>
                </Grid.Col>
            </Grid>
        </Container>
    )
}

export default EventsContainer



