import axios from 'axios';
import TokenService from './TokenService';

const API_URL = 'http://localhost:2000'; 

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
const  tokenCurrent  = TokenService.getToken()


const apiService = {

  login: async (email:string, password:string) => {
    const response = await axiosInstance.post('/auth/connexion', {
      email,
      password,
    });
    return response.data;
  },

  getProtectedUsers: async (token:string) => {
    const response = await axiosInstance.get('/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  getAllPosts: async () => {
    const response = await axiosInstance.get('/posts');
    return response.data;
  },

  createPost: async (postData:string, token:string) => {
    const response = await axiosInstance.post('/posts', postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  validateToken: async (token: string) => {
    const response = await axios.get(`${API_URL}/validate-token`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; 
  },

  createEvent: async(formData: FormData)=>{
    try {
      const response = await axios.post(`${API_URL}/event`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
          Authorization: `Bearer ${tokenCurrent}`,
        },
      });

      return response.data; 
    } catch (error) {
      console.error('Error creating event:', error);
      throw new Error('Failed to create event');
    }
  },

  getEvent: async () => {
    try {
      const response = await axios.get(`${API_URL}/event`, {});
      return response.data; 
      
    } catch (error) {
      throw new Error('Failed to get Event');
    }

  },

  deleteEvent: async (id: number) => {
    try {
      const response = await axios.delete(`${API_URL}/event/${id}`,{
        headers: {
          Authorization: `Bearer ${tokenCurrent}`,
        },
      });
      return response.data; 
    } catch (error) {
      console.error(error); 
      throw new Error('Failed to delete Event');
    }
  }

};

export default apiService;
