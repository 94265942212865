export interface Irental{
    id:number,
    name:string,
    email:string,
    date:string,
    hour:string,
    salleId:number,
    status:RentalStatus,
    supplement:string[],
    nifStat?:string,
    cin?:string,
}


export enum RentalStatus {
    Pending = 0,
    Ok = 1,
    Processing = 2,
  }

export const rentalData:Irental[]= [
    {
        id:1,
        name:"Rakotoarisoa Rivo",
        email:"rivo@gmail.com",
        date:"19-09-2024",
        hour:"10:00",
        salleId:1,
        status:0,
        supplement:["gadget","location"],
        nifStat:"156161",
        cin:"dsf"
    },
    {
        id:2,
        name:"Mamiari Brenda",
        email:"brenda@gmail.com",
        date:"19-09-2024",
        hour:"10:00",
        salleId:5,
        status:0,
        supplement:["gadget","location"],
        nifStat:"156161",
        cin:"dsf"
    },
    {
        id:3,
        name:"Felantsoa mamiarivao",
        email:"mamiarivao@gmail.com",
        date:"19-12-2024",
        hour:"15:00",
        salleId:3,
        status:0,
        supplement:["gadget","location"],
        nifStat:"156161",
        cin:"dsf"
    },
]