import { ReactNode } from "react";
import * as yup from 'yup';

export type ContactCardProps = {
    label: string
    value: string;
    icon: ReactNode;
};

export const schema = yup.object().shape({
    email: yup.string().email('Email invalide').required('Email requis'),
    phone: yup.string().matches(/^\+?[0-9]*$/, 'Numéro de téléphone invalide').required('Téléphone requis'),
    address: yup.string().required('Adresse requise'),
    facebook: yup.string().url('URL invalide').required('Lien Facebook requis'),
    instagram: yup.string().url('URL invalide').required('Lien Instagram requis'),
    youtube: yup.string().url('URL invalide').required('Lien YouTube requis'),
});

export type FormValues = {
    email: string;
    phone: string;
    address: string;
    facebook: string;
    instagram: string;
    youtube: string;
};


