import { Button, Container, Grid, Group, Space, Table, Tooltip } from '@mantine/core'
import TitlePage from '../../../shared/components/TitlePage'
import { IconBuilding, IconEdit, IconTrash } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { Irental, rentalData } from '../constants/rental.constant'

const RentalCotanainer = () => {
    const navigate = useNavigate();

    return (
        <Container fluid>
            <TitlePage title="Location salle" icon={<IconBuilding size="2rem" stroke={1.5} />} />
            <Space h="lg" />
            <Group justify="flex-end">
                <Button  variant="filled" color="grape" onClick={() => (navigate("/rental/reservation"))} >
                    Réserver
                </Button>
            </Group>
            <Space h="lg" />
            <Grid>
                <Grid.Col span={{ base: 12 }}>
                    <Table stickyHeader striped highlightOnHover horizontalSpacing="xl" withRowBorders={false}>
                        <Table.Thead style={{ backgroundColor: 'purple', color: "white" }}>
                            <Table.Tr>
                                <Table.Th>#</Table.Th>
                                <Table.Th>Nom et prenom</Table.Th>
                                <Table.Th>Email</Table.Th>
                                <Table.Th>Date</Table.Th>
                                <Table.Th>Heures</Table.Th>
                                <Table.Th>Salle</Table.Th>
                                <Table.Th>Supplement</Table.Th>
                                <Table.Th>NIF/STAT ou CIN</Table.Th>
                                <Table.Th>Status</Table.Th>
                                <Table.Th>Actions</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {
                                rentalData.map((item: Irental, i: number) => (
                                    <Table.Tr key={item.id}>
                                        <Table.Td>{i + 1}</Table.Td>
                                        <Table.Td>{item.name}</Table.Td>
                                        <Table.Td>{item.email}</Table.Td>
                                        <Table.Td>{item.date}</Table.Td>
                                        <Table.Td>{item.hour}</Table.Td>
                                        <Table.Td>{item.salleId}</Table.Td>
                                        <Table.Td>{item.supplement.toString()}</Table.Td>
                                        <Table.Td>{item.cin ?? item.nifStat}</Table.Td>
                                        <Table.Td>{item.status}</Table.Td>
                                        <Table.Td>
                                            <Button.Group>
                                                <Tooltip label="Editer">
                                                    <Button variant="default"><IconEdit color="#00abfb" /></Button>
                                                </Tooltip>
                                                <Tooltip label="Supprimer">
                                                    <Button variant="default"><IconTrash color="#ff2825" /></Button>
                                                </Tooltip>
                                            </Button.Group>
                                        </Table.Td>
                                    </Table.Tr>
                                ))
                            }
                        </Table.Tbody>
                    </Table>
                </Grid.Col>
            </Grid>
        </Container>
    )
}

export default RentalCotanainer
