import { Container, Grid, Select, TextInput } from '@mantine/core'
import { IconBuilding } from '@tabler/icons-react'
import React from 'react'
import TitlePage from '../../../shared/components/TitlePage'

const FormsRentalContainer = () => {
  return (
    <Container fluid>
      <TitlePage title="Formulaire de réservation" icon={<IconBuilding size="2rem" stroke={1.5} />} />
      <Grid>
        <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
          <TextInput
            label="Nom et prenom"
            placeholder="Entrez le nom et le prenom"
            name="name"
            required
            my={15}
          />
          <Select
            label="Type d'événement"
            placeholder="Sélectionner le type"
            data={['Salle 1', 'Salle 2', 'Salle 3']}
            required
            my={15}

          />

          <TextInput
            label="Heure de la réservation"
            placeholder="Entrez l'heure"
            name="hour"
            required
            my={15}
          />

          <TextInput
            label="NIF/STAT"
            placeholder="Entrez le Nif/stat"
            name="nifStat"
            my={15}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
          <TextInput
            label="Email"
            placeholder="Entrez l'Email"
            name="name"
            required
            my={15}
          />

          <TextInput
            label="Date de la réservation"
            placeholder="Entrez la date"
            name="date"
            required
            my={15}
          />

          <TextInput
            label="Supplement"
            placeholder="Entrez les supplements"
            name="supplement"
            required
            my={15}
          />

          <TextInput
            label="CIN"
            placeholder="Entrez le CIN"
            name="cin"
            my={15}
          />


        </Grid.Col>

        <Grid.Col span={{ base: 12 }}>
          {/* <Group justify="flex-end" gap="md">
            <Button mt="md" variant="filled" color="teal" leftSection={<IconDeviceFloppy size={14} />} onClick={() => (navigate("/events"))}>
              Enregistrer
            </Button>
            <Button mt="md" variant="filled" color="pink" leftSection={<IconX size={14} />} onClick={() => (navigate("/events"))}>
              Annuler
            </Button>
          </Group> */}

        </Grid.Col>
      </Grid>
    </Container>
  )
}

export default FormsRentalContainer
