export interface Iroom{
    id:number,
    name:string,
    type:string,
    surface:number,
    personMin?:number,
    personMax?:number,
    supplement?:string[],
    url?:string
}

export const locationData:Iroom[] = [
    {
        id: 1,
        type: "Petite salle de réunion",
        name: "makay",
        surface: 20,
        url: "#",
        personMin: 8,
        personMax: 12,
        supplement: [
            "ecran",
            "climatiseur"
        ]
    },
    {
        id: 2,
        type: "Salle de formation",
        name: "tsingy",
        surface: 30,
        url: "#",
        personMin: 8,
        personMax: 12,
        supplement: [
            "Modulable",
            "chaise",
            "table",
            "climatiseur",
            "Location à l’heure ",
        ]
    },
    {
        id: 3,
        type: "Grande salle de réunion",
        name: "ANAKAO",
        surface: 35,
        url: "#",
        personMin: 15,
        personMax: 25,
        supplement: [
            "ecran",
            "climatiseur"
        ]
    },
    {
        id: 4,
        type: "Salle de conference",
        name: "diantsoa",
        surface: 200,
        url: "#",
        personMin: 100,
        personMax: 180,
        supplement: [
            "climatiseur",
            "2 écran + sono"
        ]
    },
    {
        id: 5,
        type: "Espace polyvalent",
        name: "TIAKOLO",
        surface: 200,
        url: "#",
        personMin: 100,
        personMax: 180,
        supplement: [
            "Galerie d'exposition + balcon",
        ]
    },
    {
        id: 6,
        type: "Salle numérique",
        name: "ARINDRA",
        surface: 90,
        url: "#",
        personMin: 20,
        personMax: 30,
        supplement: [
            "climatiseur + 20 ordinateurs"
        ]
    },

]