
export interface Ipartener {
    id: number,
    name: string,
    salleId: number,
    desc: string,
    url: string
}

export const partenerData: Ipartener[] = [
    {
        id: 1,
        name: "ONG FEHIKANTO",
        salleId: 1,
        desc: "Programme d'éducation artistique",
        url: "https://www.facebook.com/profile.php?id=100091921004027"
    },
    {
        id: 2,
        name: "IARIVO MUSIC ACADEMY",
        salleId: 2,
        desc: "Cours de Piano , guitare, violon et valiha",
        url: "https://www.facebook.com/profile.php?id=100063702152864"
    }
]