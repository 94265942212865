// export function convertToISO(dateString: string): string {
//     const date = new Date(dateString);
//     return date.toISOString();
// }


export function convertToISO(dateString: string | undefined | null): string {
    if (!dateString) return ""; // Retourne une chaîne vide si dateString est null ou undefined
    
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "" : date.toISOString(); // Vérifie si la date est valide
  }