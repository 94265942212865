import { Button, Container, Grid, Group, Space, Table } from '@mantine/core'
import React from 'react'
import TitlePage from '../../../shared/components/TitlePage'
import { IconAffiliate, IconPlus } from '@tabler/icons-react'
import { Ipartener, partenerData } from '../constants/partener.constant'

const PartenerContainers = () => {
    return (
        <Container fluid>
            <TitlePage title="Collaborateurs/Partenaires" icon={<IconAffiliate size="2rem" stroke={1.5} />} />
            <Space h="lg" />
            <Group justify="flex-end">
                <Button leftSection={<IconPlus size={14} />} variant="filled" color="grape" >
                    Ajouter
                </Button>
            </Group>
            <Space h="lg" />
            <Grid>

                <Grid.Col span={{ base: 12 }}>
                    <Table stickyHeader striped highlightOnHover horizontalSpacing="xl" withRowBorders={false}>
                        <Table.Thead style={{ backgroundColor: 'purple', color: "white" }}>
                            <Table.Tr>
                                <Table.Th>#</Table.Th>
                                <Table.Th>Nom</Table.Th>
                                <Table.Th>Salle</Table.Th>
                                <Table.Th>Description</Table.Th>
                                <Table.Th>url</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {
                                partenerData.map((item: Ipartener, i: number) => (
                                    <Table.Tr key={item.id}>
                                        <Table.Td> {i+1} </Table.Td>
                                        <Table.Td> {item.name} </Table.Td>
                                        <Table.Td> {item.salleId} </Table.Td>
                                        <Table.Td> {item.desc} </Table.Td>
                                        <Table.Td> {item.url} </Table.Td>
                                    </Table.Tr>
                                ))
                            }
                        </Table.Tbody>
                    </Table>
                </Grid.Col>
            </Grid>
        </Container>
    )
}

export default PartenerContainers
