export enum Role {
    SUPER_ADMIN = "Super administrateur",
    ADMINISTRATEUR = "Administrateur",
    MODERATEUR = "Moderateur",
    UTILISATEUR = "Utilisateur"
}

export interface User {
    id: number;
    nom: string;
    prenom: string;
    email: string;
    motDePasse: string;
    photo?: string;
    role: Role;
    actif: boolean;
}