import { Button, Container, Grid, Group, Space, Table, Tooltip } from '@mantine/core'
import { IconDoor, IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'
import TitlePage from '../../../shared/components/TitlePage'
import { Iroom, locationData } from '../constants/room.constant'
import { useNavigate } from 'react-router-dom'

const Roomcontainer = () => {
    const navigate = useNavigate();
    return (
        <Container fluid>
            <TitlePage title="Salle" icon={<IconDoor size="2rem" stroke={1.5} />} />
            <Space h="lg" />
            <Group justify="flex-end">
                <Button leftSection={<IconPlus size={14} />} variant="filled" color="grape" onClick={()=>(navigate("/room/edit"))} >
                    Ajouter
                </Button>
            </Group>
            <Space h="lg" />
            <Grid>
                <Grid.Col span={{ base: 12 }}>
                    <Table stickyHeader striped highlightOnHover horizontalSpacing="xl" withRowBorders={false}>
                        <Table.Thead style={{ backgroundColor: 'purple', color: "white" }}>
                            <Table.Tr>
                                <Table.Th>#</Table.Th>
                                <Table.Th>Nom</Table.Th>
                                <Table.Th>Type</Table.Th>
                                <Table.Th>Surface</Table.Th>
                                <Table.Th>Personne</Table.Th>
                                <Table.Th>Supplement</Table.Th>
                                <Table.Th>Action</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {
                                locationData.map((item: Iroom, i: number) => (
                                    <Table.Tr key={item.id}>
                                        <Table.Td>{i + 1}</Table.Td>
                                        <Table.Td>{item.name}</Table.Td>
                                        <Table.Td>{item.type}</Table.Td>
                                        <Table.Td>{item.surface} m&sup2;</Table.Td>
                                        <Table.Td>{item.personMin + " à " + item.personMax} </Table.Td>
                                        <Table.Td>{item.supplement?.toString()} </Table.Td>
                                        <Table.Td>
                                            <Button.Group>
                                                <Tooltip label="Editer">
                                                    <Button variant="default"><IconEdit color="#00abfb" /></Button>
                                                </Tooltip>
                                                <Tooltip label="Supprimer">
                                                    <Button variant="default"><IconTrash color="#ff2825" /></Button>
                                                </Tooltip>
                                     
                                            </Button.Group>
                                        </Table.Td>
                                    </Table.Tr>
                                ))
                            }
                        </Table.Tbody>
                    </Table>
                </Grid.Col>
            </Grid>
        </Container>
    )
}

export default Roomcontainer
