import React, { ReactNode } from 'react'
import { Title, Divider, Stack, Group } from '@mantine/core';

type TitleProps = {
  title: string;
  icon: ReactNode;
};

const TitlePage: React.FC<TitleProps> = ({ title, icon }) => {
  return (
    <Stack style={{ paddingBottom: '30px', paddingTop: '15px' }}>
      <Group align="center" style={{ color: '#9C2781' }}>
        {icon}
        <Title order={1} >
          {title}
        </Title>
      </Group>
      <Divider mt="md" />
    </Stack>
  );
};

export default TitlePage
